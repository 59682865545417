import React from "react";
import { Link } from "gatsby";

const Footer = ({ noWave, rtl }) => {
  const handleMouseMove = (event) => {
    const dropDownToggler = event.target.classList.contains("dropdown-toggle")
      ? event.target
      : event.target.querySelector(".dropdown-toggle");
    const dropDownMenu = dropDownToggler?.nextElementSibling;

    dropDownToggler?.classList?.add("show");
    dropDownMenu?.classList?.add("show");
  };

  const handleMouseLeave = (event) => {
    const dropdown = event.target.classList.contains("dropdown")
      ? event.target
      : event.target.closest(".dropdown");
    const dropDownToggler = dropdown.querySelector(".dropdown-toggle");
    const dropDownMenu = dropdown.querySelector(".dropdown-menu");

    dropDownToggler?.classList?.remove("show");
    dropDownMenu?.classList?.remove("show");
  };

  return (
    <footer
      className={`style-4 ${noWave ? "mt-0 pt-100" : ""}`}
      data-scroll-index="8"
    >
      <div className="container">
        <div className="section-head text-center style-4">
          <h2 className="mb-10">
            {rtl ? "جاهز" : "Escala tu"}{" "}
            <span>{rtl ? "للتنزيل" : "Negocio"}</span>{" "}
          </h2>
          <p className="mb-10">
            {rtl
              ? "اكتشف مساحاتك المفضلة الجديدة ، من ساو باولو إلى سيول. قم بالتنزيل من App Store أو Google Play."
              : "Haz crece tu negocion con tukifac, factura y contrala tus procesos sin límite, con una herramienta que se adapta a ti."}
          </p>
          <div className=" col-lg-2 container text-center">
            {rtl ? "Zcontable" : "Garantizado por:"}{" "}
            <a
              className="logo"
              href="https://zcontable.com"
              target="_blank"
              rel="noreferrer"
            >
              {" "}
              <img
                src="/assets/img/logo-completo-zcontable-nuevo.webp"
                alt=""
              />
            </a>
          </div>
          <div className="d-flex align-items-center justify-content-center mt-50">
            <a
              href="https://wa.me/51908942077"
              rel="noreferrer"
              className="btn rounded-pill bg-blue4 fw-bold text-white me-4"
              target="_blank"
            >
              <small>
                {" "}
                <i className="fab fa-whatsapp me-2 pe-2 border-end"></i>{" "}
                {rtl
                  ? "تنزيل من متجر التطبيقات"
                  : "Contrata el servicio ahora."}
              </small>
            </a>
            <a
              href="https://wa.me/51908942077"
              rel="noreferrer"
              className="btn rounded-pill hover-blue4 fw-bold border-blue4"
              target="_blank"
            >
              <small>
                {" "}
                <i className="fab fa-whatsapp me-2 pe-2 border-end"></i>{" "}
                {rtl ? "تنزيل من متجر ابل" : "Contacta para una demo"}
              </small>
            </a>
          </div>
        </div>
        <div className="foot mt-80">
          <div className="row align-items-center">
            <div className="col-lg-2">
              <div className="logo">
                <img
                  src="https://tnyywwxijfmggxfzrlex.supabase.co/storage/v1/object/public/public_images/Tukifac-large-ver-2-_2_.png"
                  alt=""
                />
              </div>
            </div>
            <div className="col-lg-8">
              <ul className="links">
                {/* <li>
                  <a href="#clients">{ rtl ? 'الرئيسية' : 'Inicio' }</a>
                </li> */}
                <li>
                  <a href="#about">{rtl ? "من نحن" : "Nosotros"}</a>
                </li>
                <li>
                  <a href="#features">{rtl ? "المنتجات" : "Características"}</a>
                </li>
                <li>
                  <a href="#screenshots">{rtl ? "المشاريع" : "Screenshots"}</a>
                </li>
                <li>
                  <a href="#pricing">{rtl ? "الخدمات" : "Precios"}</a>
                </li>
                <li>
                  <a href="#faq">{rtl ? "الاخبار" : "FAQ"}</a>
                </li>
                <li>
                  <a href="#community">{rtl ? "اتصل بنا" : "Comunidad"}</a>
                </li>
              </ul>
            </div>
            {/* <div className="col-lg-2">
              {
                rtl ?
                (
                  <div className="dropdown" onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                    <button className="icon-25 dropdown-toggle p-0 border-0 bg-transparent rounded-circle img-cover" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <img src="/assets/img/lang.png" alt="" className="me-2" />
                        <small>العربية</small>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li><a className="dropdown-item" href="#">English</a></li>
                    </ul>
                  </div>
                )
                :
                (
                  <div className="dropdown" onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
                    <button className="icon-25 dropdown-toggle p-0 border-0 bg-transparent rounded-circle img-cover" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <img src="/assets/img/lang.png" alt="" className="me-2" />
                        <small>English</small>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li><a className="dropdown-item" href="#">English</a></li>
                      <li><a className="dropdown-item" href="#">Arabic</a></li>
                    </ul>
                  </div>
                )
              }
            </div> */}
          </div>
        </div>
        <div className="copywrite text-center">
          <small className="small">
            {rtl ? "© 2022 حقوق التأليف والنشر" : "© 2022 Copyrights by"}{" "}
            <a href="#" className="fw-bold text-decoration-underline">
              Tukifac.
            </a>{" "}
            {rtl
              ? "كل الحقوق محفوظة. صمم بواسطة"
              : "Todos los derechos reservados, desarrollado por"}{" "}
            <a
              href="https://qlab.pe"
              className="fw-bold text-decoration-underline"
            >
              Qlab.pe
            </a>
          </small>
        </div>
      </div>
      {!noWave && (
        <img
          src="/assets/img/footer/footer_4_wave.png"
          alt=""
          className="wave"
        />
      )}
    </footer>
  );
};

export default Footer;
