import React from "react";
import { Link } from "gatsby";

const Navbar = ({ navbarRef }) => {
  const handleMouseMove = (event) => {
    const dropDownToggler = event.target.classList.contains("dropdown-toggle")
      ? event.target
      : event.target.querySelector(".dropdown-toggle");
    const dropDownMenu = dropDownToggler?.nextElementSibling;

    dropDownToggler?.classList?.add("show");
    dropDownMenu?.classList?.add("show");
  };

  const handleMouseLeave = (event) => {
    const dropdown = event.target.classList.contains("dropdown")
      ? event.target
      : event.target.closest(".dropdown");
    const dropDownToggler = dropdown.querySelector(".dropdown-toggle");
    const dropDownMenu = dropdown.querySelector(".dropdown-menu");

    dropDownToggler?.classList?.remove("show");
    dropDownMenu?.classList?.remove("show");
  };

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light style-4"
      ref={navbarRef}
    >
      <div className="container">
        <a className="navbar-brand" href="#">
          <img
            src="https://tnyywwxijfmggxfzrlex.supabase.co/storage/v1/object/public/public_images/Tukifac-large-ver-2-_2_.png"
            alt="logo-tukifac"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav m-auto mb-2 mb-lg-0 text-uppercase">
            <li
              className="nav-item dropdown"
              onMouseMove={handleMouseMove}
              onMouseLeave={handleMouseLeave}
            >
              <a
                className="nav-link "
                href="#header"
                id="navbarDropdown1"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#about">
                Nosotros
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#features">
                Características
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#screenshots">
                Screenshots
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#pricing">
                Precios
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#faq">
                FAQ
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#community">
                Comunidad
              </a>
            </li>
          </ul>
          <div className="nav-side">
            <div className="d-flex align-items-center">
              {/* <a href="#" className="search-icon me-4">
               <i className="bi bi-person"></i>
              </a> */}
              <Link
                to="https://wa.me/51908942077"
                target="_blank"
                className="btn rounded-pill brd-gray hover-blue4 sm-butn fw-bold"
              >
                <span>
                  Contacto <i className="bi bi-arrow-right ms-1"></i>{" "}
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
